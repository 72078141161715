@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@import "flexmonster/flexmonster.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: black;
  background-color: rgb(238, 242, 246);
}

.drag-wrapper {
  cursor: -webkit-grab;
  cursor: grab;
}

.layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

.react-grid-item {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
}

.react-resizable {
  position: relative;
  overflow: hidden;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  background-color: #F1F1F1;
  border: 1px solid #DDDDDD;
}

/* styles.css */

/* Define scrollbar styles */
/* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(167, 167, 167);
} */
