.search-container {
    width: 280px;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
  }
  
  .dropdown:empty {
    border: none;
  }
  
  .dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
  }
  
  input {
    width: 220px;
  }
  
  .search-inner {
    display: flex;
  }
  
  .selected {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: grey
  }